import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Contato !`}</h1>
    <p><a parentName="p" {...{
        "href": "mailto:atendimento@verbasa.com.br"
      }}>{`atendimento@verbasa.com.br`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      